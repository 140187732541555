export const DICTIONARY_WORD_LIST = []

export const DICTIONARY_WORD_LIST_REAL = [
    { label: "¿qué-pasa?" },
    { label: "abrazar" },
    { label: "abrir-cajón" },
    { label: "abrir-puerta" },
    { label: "abuelo" },
    { label: "aburrido" },
    { label: "acercarse" },
    { label: "adivina" },
    { label: "adivino" },
    { label: "agarrar-botella" },
    { label: "agarrar-ropa" },
    { label: "agrupar" },
    { label: "ahi" },
    { label: "ahora" },
    { label: "ah-ya" },
    { label: "alejar-de-la-persona" },
    { label: "algunos" },
    { label: "alla" },
    { label: "alto" },
    { label: "amanecer" },
    { label: "amigo" },
    { label: "anillo" },
    { label: "animal" },
    { label: "año" },
    { label: "anochecer" },
    { label: "antes" },
    { label: "arete" },
    { label: "arroz-chaufa" },
    { label: "atardecer" },
    { label: "atencion" },
    { label: "atrapar" },
    { label: "avion" },
    { label: "avisar" },
    { label: "ayudar" },
    { label: "bailar" },
    { label: "baja-temperatura" },
    { label: "baño" },
    { label: "barrer" },
    { label: "bastante" },
    { label: "beso" },
    { label: "bicicleta" },
    { label: "bien" },
    { label: "bola-de-cristal" },
    { label: "bolsillo" },
    { label: "bombero" },
    { label: "bonito" },
    { label: "bote" },
    { label: "brujeria" },
    { label: "bueno" },
    { label: "bus" },
    { label: "buscar" },
    { label: "buscar-recipente" },
    { label: "caja" },
    { label: "caja-pequeña" },
    { label: "caliente" },
    { label: "calor" },
    { label: "cama" },
    { label: "cambiar" },
    { label: "cambiar-ropa" },
    { label: "caminar" },
    { label: "caminar-persona" },
    { label: "camioneta" },
    { label: "captar" },
    { label: "cargar" },
    { label: "cargar-caja" },
    { label: "cargar-mochila" },
    { label: "carro" },
    { label: "cartas-de-tarot" },
    { label: "casa" },
    { label: "casaca" },
    { label: "casar" },
    { label: "casarse" },
    { label: "casualidad" },
    { label: "catorce" },
    { label: "celular" },
    { label: "cerrar-cajon" },
    { label: "cerrar-cajón" },
    { label: "cerrar-cierre" },
    { label: "chancho" },
    { label: "chau" },
    { label: "chocar" },
    { label: "cien" },
    { label: "cincuenta" },
    { label: "ciudad" },
    { label: "clase" },
    { label: "colegio" },
    { label: "colocar-botella" },
    { label: "combi" },
    { label: "comer" },
    { label: "comer-manzana" },
    { label: "como" },
    { label: "comprar" },
    { label: "comunicar" },
    { label: "conducir" },
    { label: "contar" },
    { label: "contar-billetes" },
    { label: "contar-manual" },
    { label: "contricantes" },
    { label: "conversar" },
    { label: "conyuge" },
    { label: "cornudo" },
    { label: "correr" },
    { label: "cortina" },
    { label: "cortina-roller" },
    { label: "cruzar-avion" },
    { label: "cruzar-bicicleta" },
    { label: "cruzar-bote" },
    { label: "cruzar-el-cielo" },
    { label: "cual" },
    { label: "cuando" },
    { label: "cuanto" },
    { label: "cuantos" },
    { label: "cuarenta" },
    { label: "cuatro" },
    { label: "cuerpo" },
    { label: "cuidado" },
    { label: "cumpleaños" },
    { label: "cuna" },
    { label: "curiosidad" },
    { label: "dame" },
    { label: "dar" },
    { label: "dar-la-espalda" },
    { label: "dar-pasos" },
    { label: "de-acuerdo" },
    { label: "debajo" },
    { label: "deber" },
    { label: "decir" },
    { label: "demostrar" },
    { label: "dentro" },
    { label: "deporte" },
    { label: "desaparecer" },
    { label: "descansar" },
    { label: "desear" },
    { label: "desigualdad" },
    { label: "desparecer" },
    { label: "despues" },
    { label: "dia" },
    { label: "dibujar" },
    { label: "diecinueve" },
    { label: "dieciocho" },
    { label: "dieciseis" },
    { label: "diecisiete" },
    { label: "diez" },
    { label: "diferente" },
    { label: "dificil" },
    { label: "dinero" },
    { label: "dinero---plata" },
    { label: "dinosaurio" },
    { label: "disculpa" },
    { label: "doblar" },
    { label: "doce" },
    { label: "doctor" },
    { label: "donde" },
    { label: "dormir" },
    { label: "dos" },
    { label: "dos-personas" },
    { label: "edad" },
    { label: "ellos" },
    { label: "embarazo" },
    { label: "empezar" },
    { label: "empujar" },
    { label: "enamorado" },
    { label: "encender-motor" },
    { label: "encontrar" },
    { label: "encontrarse" },
    { label: "engañar" },
    { label: "engordar" },
    { label: "enseñar" },
    { label: "entender" },
    { label: "entrar" },
    { label: "entrar-persona" },
    { label: "entregar" },
    { label: "entrenar" },
    { label: "enviar" },
    { label: "esconder" },
    { label: "escribir" },
    { label: "esos" },
    { label: "esperar" },
    { label: "esquina" },
    { label: "estar-bien" },
    { label: "estudiar" },
    { label: "examen" },
    { label: "facil" },
    { label: "falta" },
    { label: "faltar" },
    { label: "fantasma" },
    { label: "fastidiar" },
    { label: "feliz" },
    { label: "fiesta" },
    { label: "fin" },
    { label: "fino" },
    { label: "firmar" },
    { label: "flaco" },
    { label: "fortachon" },
    { label: "fregado" },
    { label: "frio" },
    { label: "fundar" },
    { label: "futbol" },
    { label: "futuro" },
    { label: "gallina" },
    { label: "gato" },
    { label: "gordo" },
    { label: "gorro" },
    { label: "grabar" },
    { label: "graduarse" },
    { label: "grande" },
    { label: "grupo" },
    { label: "guardar" },
    { label: "gustar" },
    { label: "haber" },
    { label: "hacer" },
    { label: "helado" },
    { label: "helicoptero" },
    { label: "hermosa" },
    { label: "hijo" },
    { label: "historia" },
    { label: "hola" },
    { label: "hombre" },
    { label: "hora" },
    { label: "hospital" },
    { label: "hoy" },
    { label: "huaraz" },
    { label: "huevo" },
    { label: "idea" },
    { label: "iglesia" },
    { label: "igual" },
    { label: "imposible" },
    { label: "increible" },
    { label: "infiel" },
    { label: "ingresar" },
    { label: "intenso" },
    { label: "intentar" },
    { label: "interactuar" },
    { label: "invitar" },
    { label: "ir" },
    { label: "ix" },
    { label: "jefe" },
    { label: "joven" },
    { label: "juan" },
    { label: "jugar" },
    { label: "junto" },
    { label: "juntos" },
    { label: "leer" },
    { label: "lentes" },
    { label: "levantar" },
    { label: "libre" },
    { label: "libro" },
    { label: "libros" },
    { label: "limpiar" },
    { label: "lindo" },
    { label: "llamar" },
    { label: "llamar-la-atencion" },
    { label: "llave" },
    { label: "llegar" },
    { label: "llevar" },
    { label: "llevar-recipente" },
    { label: "lo-siento" },
    { label: "luego" },
    { label: "lugar" },
    { label: "malo" },
    { label: "mama" },
    { label: "mamá" },
    { label: "mañana" },
    { label: "mañana-del-dia" },
    { label: "mandar" },
    { label: "manguera" },
    { label: "mar" },
    { label: "matematica" },
    { label: "matrimonio" },
    { label: "mejor" },
    { label: "mi" },
    { label: "mono" },
    { label: "motor" },
    { label: "mucho" },
    { label: "mucho-dinero" },
    { label: "mucho-sol" },
    { label: "mucho-tiempo" },
    { label: "mujer" },
    { label: "mundo" },
    { label: "muy-alto" },
    { label: "nada" },
    { label: "nada-más" },
    { label: "necesitar" },
    { label: "niño" },
    { label: "no" },
    { label: "no-haber" },
    { label: "no-importar" },
    { label: "no-saber" },
    { label: "nueve" },
    { label: "ocupado" },
    { label: "odiar" },
    { label: "oficina" },
    { label: "ojos" },
    { label: "oye" },
    { label: "pagar" },
    { label: "pais" },
    { label: "pan" },
    { label: "papa" },
    { label: "paquete" },
    { label: "participar" },
    { label: "pasado" },
    { label: "pato" },
    { label: "pelea" },
    { label: "pelota" },
    { label: "pensar" },
    { label: "pequeño" },
    { label: "perder" },
    { label: "pero" },
    { label: "perro" },
    { label: "persiana" },
    { label: "persona" },
    { label: "persona-pequeña" },
    { label: "personas" },
    { label: "personas-juntas" },
    { label: "peru" },
    { label: "pez" },
    { label: "pila" },
    { label: "playa" },
    { label: "poco" },
    { label: "poder" },
    { label: "pollo" },
    { label: "polo" },
    { label: "poner" },
    { label: "por-favor" },
    { label: "preguntar" },
    { label: "presente" },
    { label: "prestarme" },
    { label: "primero-de-primaria" },
    { label: "problema" },
    { label: "pucha" },
    { label: "que" },
    { label: "qué" },
    { label: "querer" },
    { label: "quien" },
    { label: "quitar" },
    { label: "reloj" },
    { label: "rico" },
    { label: "robar" },
    { label: "rollo-de-monedas" },
    { label: "ropa" },
    { label: "sabado" },
    { label: "saber" },
    { label: "sacar" },
    { label: "señar" },
    { label: "sentar" },
    { label: "sentir" },
    { label: "sick" },
    { label: "siempre" },
    { label: "silla" },
    { label: "sirena" },
    { label: "sobrina" },
    { label: "sol" },
    { label: "solo" },
    { label: "sonar-telefono" },
    { label: "sordo" },
    { label: "sorpresa" },
    { label: "sostener" },
    { label: "su" },
    { label: "subir-persona" },
    { label: "sucio" },
    { label: "sudar" },
    { label: "sudar-mucho" },
    { label: "tarde" },
    { label: "telefono" },
    { label: "televisión" },
    { label: "temprano" },
    { label: "terminar" },
    { label: "tia" },
    { label: "tiempo" },
    { label: "tipear" },
    { label: "tirar" },
    { label: "toda-la-noche" },
    { label: "trabajar" },
    { label: "traer-caja" },
    { label: "tres" },
    { label: "tu" },
    { label: "universidad" },
    { label: "uno" },
    { label: "uy" },
    { label: "vamos" },
    { label: "ven" },
    { label: "venir" },
    { label: "ver" },
    { label: "vestir" },
    { label: "via-doble-sentido" },
    { label: "viajar" },
    { label: "viaje" },
    { label: "vida" },
    { label: "viejo" },
    { label: "vino" },
    { label: "visitar" },
    { label: "wow" },
    { label: "xi" },
    { label: "ya" },
    { label: "ya-ver" },
    { label: "yo" },
    { label: "zapato" },

];
